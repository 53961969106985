<template>
  <v-dialog v-model="dialog" width="460" persistent transition="dialog-bottom-transition">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Histórico - {{ user }}</v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <v-carousel v-if="voucher && voucher.voucher_history" height="300" hide-delimiter-background :show-arrows="false" :light="!darkMode">
          <v-carousel-item v-for="(voucher, index) in voucher.voucher_history" :key="index">
            <v-row no-gutters>
              <v-col cols="6">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>ID</v-list-item-title>
                    <v-list-item-subtitle>{{ voucher._id }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Ativado em</v-list-item-title>
                    <v-list-item-subtitle>{{ formatDate(voucher.activated_at) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Consumido em</v-list-item-title>
                    <v-list-item-subtitle>{{ formatDate(voucher.consumed_at) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Locked by</v-list-item-title>
                    <v-list-item-subtitle>{{ voucher.locked_by || '-' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="6">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Valor</v-list-item-title>
                    <v-list-item-subtitle>R$ {{ voucher && Number(voucher.money).toFixed(2) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Pontos</v-list-item-title>
                    <v-list-item-subtitle>{{ voucher.points }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Retornado em</v-list-item-title>
                    <v-list-item-subtitle>{{ formatDate(voucher.returned_at) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Unit</v-list-item-title>
                    <v-list-item-subtitle>{{ voucher.unit_id || '-' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { QUERY_GET_VOUCHER } from '@/modules/accounts/graphql'

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    accountId: {
      type: String,
      default: ''
    },
    voucherId: {
      type: String,
      default: ''
    },
    user: String
  },
  data: () => ({
    model: 0,
    voucher: null
  }),
  computed: {
    darkMode: {
      get() {
        return localStorage.getItem('dark_theme') === 'true'
      }
    }
  },
  mounted() {
    this.getVoucher(this.voucherId)
  },
  methods: {
    formatDate(date) {
      if (date) return this.$moment(date).format('DD/MM/YYYY, HH:mm:ss')
      return '-'
    },
    async getVoucher(id) {
      try {
        const { data } = await this.$apollo.query({
          query: QUERY_GET_VOUCHER,
          fetchPolicy: 'network-only',
          variables: {
            account_id: this.accountId,
            voucher_id: id
          }
        })
        this.voucher = data.voucher
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao encontrar voucher', snackbarColor: '#F44336' })
      }
    }
  }
}
</script>
